html {
  font-size: 14px;
  height: 100%;
}

body {
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: 'Fira Sans', sans-serif;
  background-color: var(--surface-b);
  color: var(--text-color);
  font-size: 14px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.layout-scroll-block {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.card {
  background: var(--surface-e);
  color: var(--text-color);
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  border-radius: 4px;
  margin-bottom: 1rem;
  min-width: 500px !important;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.text-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #8e8e8e;
}

.text-divider::before,
.text-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #b3b3b3;
}

.text-divider:not(:empty)::before {
  margin-right: 1em;
}

.text-divider:not(:empty)::after {
  margin-left: 1em;
}

.paragraph-with-white-space {
  white-space: pre-wrap;
}

ng::ng-deep card {
  min-width: 600px !important;
}
